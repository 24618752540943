.main-chart {
  height: 550px;
}
.technical-analysis .tradingview-widget-container {
  margin-bottom: 30px;
}
.symbol-info .tradingview-widget-container {
  margin-bottom: 15px;
}
.markets-overview .tradingview-widget-container {
  margin-bottom: 30px;
}
